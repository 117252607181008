<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-09-11 08:36:51
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-09-12 10:47:26
 * @FilePath: \official_website\src\views\contact\Contact.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div id="contact">
    <Header></Header>
    <div class="contact-content">
      <div class="contact-img">
        <div class="contact-title">人才招募</div>
      </div>
      <div class="contact-box">
        <div class="recruit-title">2023年招聘需求计划表</div>
        <div class="recruit-box">
          <ul class="recruit">
            <li
              class="recruit-list"
              v-for="(items, indexs) in recruitmentList"
              :key="indexs"
              :ref="'recruitList'+indexs"
            >
              <div class="header">
                <div class="position">{{ items.position }}</div>
                <div class="department">{{ items.department }}</div>
              </div>
              <div class="responsibility">
                <div class="title">岗位职责</div>
                <p class="post-box" v-html="items.responsibility"></p>
              </div>
              <div class="job-request">
                <div class="title">岗位要求</div>
                <p class="job-box" v-html="items.jobRequest"></p>
              </div>
              <div class="demand-area">
                <div class="title">需求区域</div>
                <div class="demand-box">
                  <ul class="demand">
                    <li
                      class="demand-list"
                      v-for="(item, index) in items.demandArea"
                      :key="index"
                    >
                      <div class="left">
                        <span class="area">{{ item.area }}</span>
                        <span class="number">{{ item.number }}</span>
                      </div>
                      <div class="right">{{ item.provice }}</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="buttons">
                <span v-if="!items.foldFlag" @click="viewMore(indexs)" class="more">查看更多</span>
                <span v-else class="retract"@click="retract(indexs)" >收起</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import Header from "../componets/Header.vue";
import Footer from "../componets/Footer.vue";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      recruitmentList: [
        {
          position: "省区经理",
          department: "销售本部",
          responsibility: `1、负责对区域市场开拓进行策划并执行销售策略。 <br />
                  2、熟悉公司理念和产品，了解客户需求，能够为客户提出个性化的解决方案。<br />
                  3、提供区域内客户反馈方面的准确信息。<br />
                  4、负责区域建设及业务开展工作，汇报每天区域销售工作进度。<br />
                  5、配合上级完成学术会议的统筹工作，主导区域内学术会议的召开及协调工作。<br />
                  6、负责区域费用使用、发放、货款回笼工作。 <br />
                  7、参与区域重要客户的业务谈判及客情拜访。 <br />
                  8、负责分销渠道的建立。 <br />
                  9、负责区域客户投诉处理。`,
          jobRequest: `1、临床医学、检验，病理，或市场营销等相关专业。 <br />
                  2、3年以上医疗器械或医疗相关销售经验。 <br />
                  3、较强的沟通协调及逻辑思维能力，具备良好的销售技巧及谈判能力。<br />
                  4、具有高度的执行力，有很强的统筹组织和沟通协调能力，富有团队精神。`,
          demandArea: [
            {
              area: "南部大区",
              number: "4人",
              provice: "福建、浙江、安徽/江西、上海",
            },
            {
              area: "西部大区",
              number: "5人",
              provice: "云南、贵州、新疆、重庆、广西",
            },
            {
              area: "北部大区",
              number: "1人",
              provice: "河南",
            },
          ],
        },
        {
          position: "市场总监",
          department: "市场本部",
          responsibility: `1、负责公司的市场推广、品牌宣传、新产品上市、会展等工作的组织、实施；<br/>
                2、根据公司的销售指标制订市场运作年度和月度计划，并组织实施；<br/>
                3、定期组织市场调研，收集市场信息分析市场动向、特点和发展趋势，制定市场销售策略，确定重要的目标市场、市场结构和销售策略；<br/>
                4、参与制定公司营销策略，包括价格、产品、促销、渠道等；<br/>
                5、定期召开市场部例会、分析计划实施情况、并加以改进和完善；<br/>
                6、组织产品营销活动、宣传企业产品和服务、对销售效果进行分析；<br/>
                7、负责重要宣传资料的制作和审核；<br/>
                8、负责协调市场部和其他部门的关系；<br/>
                9、完成领导交付的其他工作任务。`,
          jobRequest: `1、生物、医学、检验类相关专业本科及以上学历；<br/>
                2、具有医疗市场、渠道等方面5年以上工作经验，有相关医学专家资源；<br/>
                3、具有较强商务谈判技能，善于灵活变通，能承受较强的工作压力；<br/>
                4、具有敏锐的市场意识、应变能力和市场开拓能力；<br/>
                5、具有强烈的进取心，富有开拓精神，具有良好的职业操守和职业素养。`,
          demandArea: [
            {
              area: "总部",
              number: "1人",
              provice: "长沙",
            },
          ],
        },
        {
          position: "市场经理（上量工作）",
          department: "市场本部",
          responsibility: `1、 负责全国的试剂增量工作；<br/>
                2、市场专家建设工作；<br/>
                3、策划及组织会议（院内推广会、客户交流会、经销商会、学术会），并作学术讲课；<br/>
                4、 负责经销商的产品培训工作；<br/>
                5、收集与分析市场信息、政策，了解同行业发展的市场动态。`,
          jobRequest: `1、医学检验、生物技术、生物工程、医学等相关专业本科及以上学历；<br/>
                2、同岗位工作经验5年以上；<br/>
                3、有市场营销知识、医学检验相关知识；<br/>
                4、具有责任感、诚信、良好的沟通能力及团队领导能力；<br/>
                5、能适应出差。`,
          demandArea: [
            {
              area: "总部",
              number: "1人",
              provice: "长沙",
            },
          ],
        },
        {
          position: "深度学习",
          department: "仪器研发生产部",
          responsibility: `1、从事图像分析与理解领域的技术研发与工程落地，将更多人工智能体验带给亿万用户；<br/>
                2、将深度学习技术运用到医学显微镜图像的自动识别、目标检测与分割等；<br/>
                3、深度学习模型的构建与优化，提升准确性、鲁棒性、速度与易用性。`,
          jobRequest: `1、计算机或者相关领域本科以上学历，硕士及博士学历者优先；<br/>
                2、在机器学习上有实战经验，对经典算法如BOOSTING、CNN、SVM等有深入理解；<br/>
                3、需要有图像处理或者计算机视觉领域的研究或者工作经历，熟悉C、python及OpenCV；<br/>
                4、有强悍的技术攻关能力和实际动手能力，头脑聪明，学习能力强，主动性好；<br/>
                5、有在COCO、ImageNet等权威数据库上提交过结果并取得优异成绩者优先；<br/>
                6、熟悉深度学习网络的GPU并行计算及异构计算者优先。`,
          demandArea: [
            {
              area: "总部",
              number: "1人",
              provice: "长沙",
            },
          ],
        },
        {
          position: "电子工程师",
          department: "仪器研发生产部",
          responsibility: `1. 负责产品的硬件方案设计与详细设计，完成电路原理与PCB设计，完成电子相关的设计文档编写；<br/>
                2. 负责电子元器件的选型、验证和检验标准制定，以及样机调试；<br/>
                3. 协助注册部完成产品的环境、安规和EMC检测与整改；<br/>
                4. 协助部门领导或项目负责人管理、推进研发项目；<br/>
                5. 按时高质量完成领导下达研发任务；`,
          jobRequest: `1. 本科及以上学历，电子、自动化等相关专业毕业；<br/>
                2. 扎实的模拟电路和数字电路理论基础，具有良好的电路设计、调试能力，熟悉常用的电子元器件；<br/>
                3. 熟悉PCB工艺，2年以上电子开发经验，至少独立承担、主导一个项目或产品电子设计工作；有医疗器械产品设计经验者更佳；<br/>
                4. 具有EMC、EMI等相关经验，有复杂电磁环境产品设计经验者更佳；<br/>
                5. 熟练使用硬件调试、测试的各种工具、仪器；<br/>
                6. 熟悉ARM/MCU嵌入式处理器及周边电路设计`,
          demandArea: [
            {
              area: "总部",
              number: "1人",
              provice: "长沙",
            },
          ],
        },
        {
          position: "嵌入式软件开发工程师",
          department: "仪器研发生产部",
          responsibility: `1. 负责公司医疗产品的嵌入式软件开发和验证工作；<br/>
                2. 负责参与软件需求分析和体系结构建立，负责模块间的沟通及评估；<br/>
                3. 按照产品开发流程进行医疗软件系统的设计、开发、策划、组织和实施；<br/>
                4. 负责研发项目产品相关技术文件资料的编制、输出；<br/>
                5. 组织实施新产品的医疗软件系统开发流程和新产品的试制工作；<br/>
                6. 协助产品注册报批等相关事项工作。`,
          jobRequest: `1、计算机、通信、自动化或电子相关专业，1年以上软件开发经验。<br/>
                2、熟悉STM32单片机，ARM架构的编程，能看懂硬件原理图；<br/>
                3、熟练使用KEIL、IAR等开发工具进行单片机编程；<br/>
                4、精通C/C++语言编程，熟悉嵌入式实时操作系统；<br/>
                5、熟悉MCU芯片的外设接口IIC、SPI、UART等，熟悉RS232,CAN等通讯接口；<br/>
                6、有步进电机/伺服电机控制等相关项目经验者优先；`,
          demandArea: [
            {
              area: "总部",
              number: "1人",
              provice: "长沙",
            },
          ],
        },
        {
          position: "机械设计工程师",
          department: "仪器研发生产部",
          responsibility: `1，负责公司医疗器械产品结构方案设计和图纸 <br/>
                2，负责与加工方沟通协调加工方案 <br/>
                3，负责公司新产品开发和现有产品优化 <br/>
                4，负责出具仪器BOM清单 <br/>
                5，负责出具生产作业指导`,
          jobRequest: `1，机械设计或者自动化专业毕业 <br/>
                2，熟练使用solidworks，CAD等设计软件 <br/>
                3，熟悉各种金属和塑料的特性及应用 <br/>
                4，熟悉各类传动机构的设计研发 <br/>
                5，熟悉机械加工工艺，表面热处理工艺 <br/>
                6，有医疗行业或自动化行业产品研发经验优先 <br/>
                7，三年以上机械设计研发经验，有机械项目管理经验优先`,
          demandArea: [
            {
              area: "总部",
              number: "1人",
              provice: "长沙",
            },
          ],
        },
        {
          position: "软件工程师",
          department: "仪器研发生产部",
          responsibility: `1、参与高端医学仪器设备的研发，包括独立功能模块的需求分析、设计、开发和测试，并参与设计方案和测试方案的编写；<br/>
                2、负责完成公司安排的C#上位机软件开发任务；<br/>
                3、根据DLL接口开发文档，完成软件功能开发与自测；<br/>
                4、根据AI接口开发AI医疗诊断软件；<br/>
                5、根据功能需求设计与开发软件模块。`,
          jobRequest: `1、本科及以上学历，4年及以上工作经验，计算机相关专业优先；<br/>
                2、精通.Net开发技术：WinForm、WPF、ASP.Net、.Net6+；<br/>
                3、精通.Net常用控件及第三方控件开发技术；<br/>
                4、熟悉串口、HTTP、TCP、UDP、USB等通讯协议；<br/>
                5、熟悉MySql、Sqlite等数据库，具备数据库性能调优能力；<br/>
                6、具备运动控制、图像分析处理、OpenCV等开发相关工作经验优先；<br/>
                7、具备软件性能调优能力优先、具备软件架构设计能力优先；<br/>
                8、熟练使用过第三方风格库或框架库或设计库优先；<br/>
                9、沟通能力良好，有团队协作意识。`,
          demandArea: [
            {
              area: "总部",
              number: "1人",
              provice: "长沙",
            },
          ],
        },
        {
          position: "生产技术员（调试）",
          department: "仪器研发生产部",
          responsibility: `1、负责公司仪器生产工作 <br/>
                2、负责仪器的维修工作 <br/>
                3、负责生产工具的保养 <br/>
                4、负责生产场地的清洁`,
          jobRequest: `1，熟悉各种装配工具的使用 <br/>
                2、熟悉基本的软件安装和使用 <br/>
                3、沟通能力强，善于观察和学习 <br/>
                4、熟练使用电络铁，热风枪，各种钳子等工具 <br/>
                5、熟悉电气接线图原理图装配图`,
          demandArea: [
            {
              area: "总部",
              number: "1人",
              provice: "长沙",
            },
          ],
        },
        {
          position: "生产工艺工程师",
          department: "仪器研发生产部",
          responsibility: `1.协助研发输出工艺参数文件，协助样机试产；负责生产试产及量产放大；实施相关设备/设施、工艺验证；完成生产过程风险分析；核对物料信息、BOM及标准工时；输出符合生产运营及质量要求的生产操作及检测SOP；<br/>
                2.负责生产检测过程中使用的工装夹具、非标检具的设计、验证；<br/>
                3.负责对生产、检验及其他相关人员进行新产品工艺流程文件上岗操作培训；<br/>
                4.负责对产品试产及量产过程中异常问题进行调查，协同研发部门完成异常处理；跟踪量产后一年内新产品生产异常状况并形成汇总分析报告。<br/>
                5.负责制定设备转产年度工作计划及小结，并整理组内计划和小结，落实到实际工作中严格按照设定计划；定期进行阶段讨论及小结，控制转产组工作进度。<br/>
                6.负责设备产品转化平台日常事务管理，含设备/设施及物料管理；成员绩效及发展计划管理；对安全运营负责。<br/>
                7.协助产品转化的系统化和标准化建设。<br/>
                8.其他事务性工作及临时工作，并对其工作内容负责。`,
          jobRequest: `1 教育背景：<br/>
                    本科学历，机械、电子/电气、流体、光学等相关专业，至少5年相关工作经验；<br/>
                    硕士学历，机械、电子/电气、流体、光学等相关专业，至少3年相关工作经验。<br/>
                2 工作经验：<br/>
                    有体外诊断产品研发、转产相关经验2-3年以上，或者担任产品工艺负责人或工艺工程师5年以上；<br/>
                    在仪器行业产品设计转化与过程设计有丰富经验；<br/>
                    熟悉先进制造业产品过程设计流程及相关标准，主持过仪器类产品设计转化工作；<br/>
                    学习能力强，具备良好的组织、协调、沟通能力；<br/>
                    熟悉ISO 13485和GMP法规要求；<br/>
                    熟悉项目管理优先。`,
          demandArea: [
            {
              area: "总部",
              number: "1人",
              provice: "长沙",
            },
          ],
        },
        {
          position: "试剂研发工程师",
          department: "生物研发生产部",
          responsibility: `1.体外诊断试剂产品研发试产及量产工艺研究，设计整个工艺流程、细节，整个方案的制定、执行及报告出具 <br/>
                2.分析数据及挖掘临床价值，指导参与项目的研发方向及优化 <br/>
                3.负责公司现有平台试剂产品的工艺优化`,
          jobRequest: `1、本科及以上学历，有3-5年相关工作经验 <br/>
                2、生物医学相关专业，有生化、荧光染色、液基细胞学经验优先 <br/>
                3、具备扎实的理论和实践基础，有创新能力，动手能力强，能独立解决问题 <br/>
                4、英文阅读能力强，能快速获取和组织文献 <br/>
                5、勇于承担责任、细心谨慎、责任心强、为人诚实，具备良好的沟通宣讲能力、执行力`,
          demandArea: [
            {
              area: "总部",
              number: "1人",
              provice: "长沙",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.recruitmentList.forEach((item) => {
      item.foldFlag = false
    })
  },
  mounted() {},
  watch: {},
  methods: {
    resetFlod() {
      this.recruitmentList.forEach((item, index) => {
        item.foldFlag = false
        this.$refs[`recruitList${index}`][0].style.height = "10.5rem"
        this.$refs[`recruitList${index}`][0].style.overflow = "hidden"
      })
    },
    viewMore(index) {
      this.resetFlod()
      this.recruitmentList.[index].foldFlag = !this.recruitmentList.[index].foldFlag
      if(this.recruitmentList.[index].foldFlag) {
        // console.log(this.$refs[`recruitList${index}`][0].style);
        this.$refs[`recruitList${index}`][0].style.height = "initial"
        this.$refs[`recruitList${index}`][0].style.overflow = "initial"
        // this.$refs[`recruitList${index}`][0].style.transition = "height 1s ease-out"
      }
      this.recruitmentList = [...this.recruitmentList]
    },
    retract(index) {
      this.resetFlod()
      // this.recruitmentList.[index].foldFlag = !this.recruitmentList.[index].foldFlag
      if(!this.recruitmentList.[index].foldFlag) { 
        this.$refs[`recruitList${index}`][0].style.height = "10.5rem"
        this.$refs[`recruitList${index}`][0].style.overflow = "hidden"
      }
      this.recruitmentList = [...this.recruitmentList]
    }
  },
};
</script>
    
<style lang="scss">
#contact {
  .contact-content {
    padding-top: 5rem;
    .contact-img {
      width: 100%;
      height: 28rem;
      background: url("../../assets/image/contact/zhpin.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: relative;
      .contact-title {
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translateY(-50%);
        color: #fff;
        font-size: 2.2rem;
        font-family: "SourceHanSansSC-Medium";
      }
    }
    .contact-box {
      padding: 5% 4% 2%;
      .recruit-title {
        font-size: 2rem;
        font-family: "SourceHanSansSC-Medium";
        text-align: center;
      }
      .recruit-box {
        padding: 5% 4% 2%;
        .recruit {
          .recruit-list {
            position: relative;
            padding: 2rem;
            background: #f5f5f5;
            border-left: 0.625rem solid #0b847b;
            border-radius: 0.625rem;
            margin-bottom: 1.5rem;
            height: 10.5rem;
            overflow: hidden;
            .header {
              display: flex;
              justify-content: space-between;
              margin-bottom: 1.2rem;
              .position {
                font-size: 1.4rem;
                font-family: "SourceHanSansSC-Medium";
              }
              .department {
                padding: 0.3rem 0.625rem;
                background-color: #e6e6e6;
                color: #666;
                font-size: 1rem;
                font-family: "OPlusSans3-Medium";
              }
            }
            .responsibility {
              margin-bottom: 1.2rem;
              .title {
                font-size: 1.2rem;
                font-family: "SourceHanSansSC-Medium";
                margin-bottom: 1rem;
              }
              .post-box {
                font-size: 0.9rem;
                font-family: "OPlusSans3-Medium";
                line-height: 2rem;
                color: #666;
              }
            }
            .job-request {
              margin-bottom: 1.2rem;
              .title {
                font-size: 1.2rem;
                font-family: "SourceHanSansSC-Medium";
                margin-bottom: 1rem;
              }
              .job-box {
                font-size: 0.9rem;
                font-family: "OPlusSans3-Medium";
                line-height: 2rem;
                color: #666;
              }
            }
            .demand-area {
              margin-bottom: 1.2rem;
              .title {
                font-size: 1.2rem;
                font-family: "SourceHanSansSC-Medium";
                margin-bottom: 1rem;
              }
              .demand-box {
                font-size: 0.9rem;
                font-family: "OPlusSans3-Medium";
                .demand {
                  display: flex;
                  .demand-list {
                    padding: 0.5rem 1rem;
                    background: #dde9e8;
                    border-radius: 3.5rem;
                    display: flex;
                    align-items: center;
                    margin-right: 2rem;
                    .left {
                      margin-right: 1rem;
                      span {
                        display: inline-block;
                      }
                      .area {
                        font-size: 1rem;
                        font-family: "SourceHanSansSC-Medium";
                      }
                      .number {
                        margin: 0 0.5rem;
                        color: #0b847b;
                      }
                    }
                    .right {
                      color: #666;
                    }
                  }
                }
              }
            }
            .buttons {
              position: absolute;
              right: 2rem;
              bottom: 1rem;
              display: flex;
              justify-content: flex-end;
              color: #0b847b;
              font-size: 0.9rem;
              font-family: "OPlusSans3-Medium";
              span {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    padding: 6.25rem 8% 3.125rem;
  }
}
</style>